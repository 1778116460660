import { API_URL, LS_USER_KEY } from '../services/config.service'

export const userService = {
    login,
    logout,
    forget,
    signin,
    subscribe,
    getFromLS,
    getAuthHeader,
}

let userToken

function getAuthHeader() {
    return { 'X-ACCESS-TOKEN': userToken }
}

function getFromLS() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))

    if (localStorageData && localStorageData.AccessToken) {
        userToken = localStorageData.AccessToken
    }

    return localStorageData
}

function login(email, password) {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)
    console.log(API_URL)
    return fetch(API_URL + '/userlogin.php', {
        method: 'POST',
        headers: {},
        body: formData,
    })
        .then(response => {
            if (!response.ok) {
                alert('Adresse email ou Mot de passe erroné')
                return Promise.reject(response)
            }
            return response.json()
        })
        .then(result => {
            if (result.AccessToken) {
                userToken = result.AccessToken
            }
            // we save auth data to local storage
            localStorage.setItem(LS_USER_KEY, JSON.stringify(result))
            return result
        })
}

function forget(email) {
    const data = new FormData()
    data.append('email', email)

    return fetch(API_URL + '/userresetpassword.php', {
        method: 'POST',
        headers: {},
        body: data,
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText)
            }
            return response.json()
        })
        .then(details => {
            return details
        })
}

function signin(
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    address,
    birthday,
) {
    const data = new FormData()
    data.append('email', email)
    data.append('password', password)
    data.append('firstName', firstName)
    data.append('lastName', lastName)
    data.append('userName', firstName + lastName)
    if (address) data.append('fulladdress', address)

    if (birthday) {
        data.append('birthday', birthday)
    }
    if (phoneNumber) {
        data.append('phonenumber', phoneNumber)
    }
    return fetch(API_URL + '/usercreate.php', {
        method: 'POST',
        headers: {},
        body: data,
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText)
            }
            return response.json()
        })
        .then(result => {
            if (result.AccessToken) {
                userToken = result.AccessToken
            }
            localStorage.setItem(LS_USER_KEY, JSON.stringify(result))
            return result
        })
}

function logout() {
    localStorage.removeItem(LS_USER_KEY)
    localStorage.removeItem('company')
    localStorage.removeItem('bookingInfo')
}

function subscribe() {
    return fetch(API_URL + '/logbook/subscribe.php', {
        method: 'POST',
        headers: userService.getAuthHeader(),
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText)
            }
            return response.json()
        })
        .then(result => {
            const cachedUser = JSON.parse(localStorage.getItem(LS_USER_KEY))
            cachedUser.User = Object.assign(cachedUser.User, result.User)
            console.log(
                'user is now subscribed, updating cache',
                result.User,
                cachedUser.User,
            )
            localStorage.setItem(LS_USER_KEY, JSON.stringify(cachedUser))
            return cachedUser
        })
}
