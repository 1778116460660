export default {
    services: {
        FR: 'Nos services',
        EN: 'Our services',
    },
    about: {
        FR: 'A PROPOS DE NOUS',
        EN: 'ABOUT US',
    },
    status:{
        FR: 'Je suis pro',
        EN: `I'm  a pro member`,
    }
    ,
    us:{
        FR: 'Le spécialiste du stockage sans engagement',
        EN: 'Specialist of storage without engagement',
    },

    entreprise:{
        FR: 'Le stockage pour les entreprises',
        EN: 'Storage for businesses',
    },
     camping:{
        FR: 'Le stockage de camping-car',
        EN: 'Camping-car storage',
    }
    ,
    boat:{
        FR: 'Le stockage de bateaux',
        EN: 'Boats storage',
    },

    knowledge:{
        FR: 'Tout savoir',
        EN: 'Know more',
    },

     we:{
        FR: 'Qui sommes-nous ?',
        EN: 'Who are we?',
    }
    ,
    button:{
        FR: 'Louer mon emplacement',
        EN: 'Rent my parking',
    }
    ,
    social:{
        FR: 'Suivez-nous sur les reseaux sociaux',
        EN: 'Follow us on social media',
    },
    partenaires:{
        FR: 'NOS PARTENAIRES',
        EN: 'OUR SPONSORS',
    }

   
   
}
