import { combineReducers } from 'redux'

import authentication from './authentication.reducer'
import alert from './alert.reducer'
import filter from './filter.reducer'
import fetch from './fetch.reducer'
import fetchAddress from './address.reducer'
import post from './post.reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    fetch,
    filter,
    post,
    fetchAddress,
})

export default rootReducer
