export default theme => ({
    content: {
        position:"relative",
        top: "10vh",
        minHeight: 1100,
        marginLeft: '20%',
        marginRight: '20%',
        fontFamily: 'Roboto',
    },
    h2: {
        marginBlockEnd: '0px'
    },
    p: {
        marginBlockStart: '0px'
    }
})
 