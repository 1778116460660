export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',

    SUBSCRIBE_REQUEST: 'USERS_LOGBOOK_REQUEST',
    SUBSCRIBE_SUCCESS: 'USERS_LOGBOOK_SUBSCRIBED',
    SUBSCRIBE_FAILURE: 'USERS_LOGBOOK_FAILURE',
}
