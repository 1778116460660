import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import classNames from "classnames";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import styles from "./Styles/SideNavigationCss";
import BoatOnComponent from "./BoatOnComponent";

import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import dictionary from "../HomePage/Dictionary/NavigationDico";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';



class SideNavigation extends BoatOnComponent {
  constructor(props) {
    super(props);
    this.dictionary = dictionary;

    this.state = {
      open: false,
      openTwo: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickTwo = this.handleClickTwo.bind(this);
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }
  handleClickTwo() {
    this.setState({ openTwo: !this.state.openTwo });
  }

  render() {
    const { classes, list, main } = this.props;
    const { open, openTwo } = this.state;

    let handleClick = this.handleClick;
    let handleClickTwo = this.handleClickTwo;

    return (
      <div
        style={{
          position: "relative",
          background: "white",
          top: 60,
          zIndex: 3,
          minHeight: 200,
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            sx={{ pl: 4, border: 1, borderColor: "#f3f3f3" }}
            onClick={() => handleClick()}
          >
            <ListItemText primary="Nos services" />
            {open ? <ExpandLessIcon/> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <a
                className={classes.navLinks}
                href="https://info.stockon.fr/tout-savoir-sur-le-stockage-sans-engagement-pour-les-entreprises/"
              >
                <ListItemButton
                  sx={{ pl: 6, border: 1, borderColor: "#f3f3f3" }}
                >
                  <div style={{ display: "flex" , placeItems: "center"}}>
                    <KeyboardArrowRightIcon/>
                    <ListItemText primary={this.displayText("entreprise")} />
                  </div>
                </ListItemButton>
              </a>
              <a
                className={classes.navLinks}
                href="https://info.stockon.fr/tout-savoir-sur-le-stockage-de-camping-car/"
              >
                <ListItemButton
                  sx={{ pl: 6, border: 1, borderColor: "#f3f3f3" }}
                >
                  <div style={{ display: "flex", placeItems: "center"}}>
                  <KeyboardArrowRightIcon/>
                    <ListItemText primary={this.displayText("camping")} />
                  </div>
                </ListItemButton>
              </a>
              <a
                className={classes.navLinks}
                href="https://www.boaton.fr/fr/chercher-un-parking"
              >
                <ListItemButton
                  sx={{ pl: 6, border: 1, borderColor: "#f3f3f3" }}
                >
                  <div style={{ display: "flex", placeItems: "center"}}>
                  <KeyboardArrowRightIcon/>
                    <ListItemText primary={this.displayText("boat")} />
                  </div>
                </ListItemButton>
              </a>
            </List>
          </Collapse>
          <ListItemButton
            sx={{ pl: 4, border: 1, borderColor: "#f3f3f3" }}
            onClick={() => handleClickTwo()}
          >
            <ListItemText primary="A propos" />
            {open ? <ExpandLessIcon/> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={openTwo} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <a
                className={classes.navLinks}
                href="https://info.stockon.fr/tout-savoir-sur-loffre-packagee-tout-en-un-de-stockon-fr/"
              >
                <ListItemButton
                  sx={{ pl: 6, border: 1, borderColor: "#f3f3f3" }}
                >
                  <div style={{ display: "flex", placeItems: "center"}}>
                  <KeyboardArrowRightIcon/>
                    <ListItemText primary={this.displayText("knowledge")} />
                  </div>
                </ListItemButton>
              </a>
              <a
                className={classes.navLinks}
                href="https://info.stockon.fr/qui-sommes-nous/"
              >
                <ListItemButton
                  sx={{ pl: 6, border: 1, borderColor: "#f3f3f3" }}
                >
                  <div style={{ display: "flex", placeItems: "center" }}>
                  <KeyboardArrowRightIcon/>
                    <ListItemText primary={this.displayText("we")} />
                  </div>
                </ListItemButton>
              </a>
            </List>
          </Collapse>
          <a
            className={classes.navLinks}
            href="https://info.stockon.fr/je-suis-pro/"
            color="inherit"
          >
            <ListItemButton sx={{ pl: 4, border: 1, borderColor: "#f3f3f3" }}>
              <ListItemText primary={this.displayText("status")} />
            </ListItemButton>
          </a>
          <a
            className={classes.navLinks}
            href="https://info.stockon.fr/faq/"
            color="inherit"
          >
            <ListItemButton sx={{ pl: 4, border: 1, borderColor: "#f3f3f3" }}>
              <ListItemText primary="FAQ" />
            </ListItemButton>
          </a>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(SideNavigation);
