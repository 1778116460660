import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#D0021B',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fec107',
            contrastText: '#000',
        },
        action: {
            active: '#D0021B',
            contrastText: '#fff',
        },
        active: {
            main: '#D0021B',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 14,
        useNextVariants: true,
        title: {
            fontFamily: 'Roboto',
        },
        button: {
            fontSize: '1.2rem',
        },
    },
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            // Name of the rule
            h1: {
                // Some CSS
                fontSize: '40px',
                fontWeight: '400',
            },
            h2: {
                fontSize: '35px',
                fontWeight: '400',
            },
            h3: {
                fontSize: '22px',
                fontWeight: '600',
            },
            subtitle1: {
                fontSize: '22px',
                lineHeight: '28px',
                fontWeight: '400',
            },
            subtitle2: {
                fontSize: '18px',
                fontWeight: '400',
            },
            body1: {
                fontSize: '14px',
                fontWeight: '400',
            },
            body2: {
                fontSize: '12px',
                fontWeight: '200',
            },
        },
        MuiButton: {
            // Name of the rule
            containedPrimary: {
                // Some CSS
                '&:hover': {
                    backgroundColor: '#FEBA06',
                    color: '#000',
                },
            },
            containedSizeSmall: {
                '&:hover': {
                    backgroundColor: '#D0021B',
                    color: '#fff',
                },
            },
        },
    },
})

export default theme
