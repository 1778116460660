const AppFAQ = {
    fr: [
        {
            question: 'Qu’est-ce qu’un journal de bord ?',
            answer:
                'Un journal de bord reprend l’essentiel des informations de votre bateau. Ces informations peuvent s’avérer capitales pour faire le point avant de prendre la mer, afin d’être certain que vous réunissez l’ensemble des équipements et conditions pour une navigation en toute sécurité. L’application BoatOn est accessible partout en France, où que vous naviguiez.',
        },
        {
            question: 'Le journal de bord est-il obligatoire ?',
            answer:
                'Dès que vous naviguez à plus de 6 milles de côtes, vous avez pour obligation de détenir un journal de bord sur votre bateau ainsi que de l’accompagner d’un livre de bord qui répertorie l’ensemble des dates des tests effectués sur le bateau (évacuation du bateau, moteur, etc.). Votre journal de bord est considéré comme acte de foi en cas de procès.',
        },
        {
            question: 'Que doit-il contenir ?',
            answer:
                'Le contenu reste cependant assez évasif selon la réglementation, datée du 11 mars 2008 : « un journal de bord contenant les éléments pertinents pour le suivi de la navigation et la sécurité du navire ». À l’image du journal de bord du marin, vous avez pour obligation selon les nombreux articles établis, de tenir à jour votre journal de bord à chaque fois que vous prenez la mer. Vous devez y répertorier : - Tout ce qui entoure les conditions météorologiques ; - L’ensemble des conditions de votre environnement nautique, avec les conditions de la marée, la vitesse, le cap, l’état de la mer, ainsi que les coordonnées géographiques ;- Votre environnement aérien, en précisant l’état du ciel, la force du vent, et l’indication du baromètre ; - Votre équipage, soit le skipper ainsi que les équipiers qui le secondent, - Enfin, bien entendu l’heure à laquelle vous quittez le port et la date, sans oublier de préciser votre destination et les escales potentielles réalisées. La tenue de votre journal de bord de navigation doit donc permettre d’assurer la sécurité des passagers sur le bateau, ainsi que de connaître le nombre exact de l’équipage.',
        },
        {
            question: 'Comment rédiger un livre de bord ?',
            answer:
                'Le livre de bord vient compléter le journal de bord. Il vient apporter des informations complémentaires concernant la navigation. Si vous avez pour habitude de prendre la mer, vous savez sans aucun doute qu’elle peut devenir très changeante et capricieuse en quelques heures. Pour prévenir de tout incident, il est conseillé de prendre des notes régulières toutes les 3h. Ainsi, vous pouvez facilement repérer un changement sensible de votre environnement (météorologique, technique, marée, etc.),',
        },
    ],
    en: [
        {
            question: 'What is a logbook?',
            answer:
                'A logbook contains the essential information about your boat. This information can be vital to take stock before setting out to sea, to make sure you have all the equipment and conditions for safe sailing. The BoatOn application is available everywhere in France, wherever you sail.',
        },
        {
            question: 'Is the logbook obligatory?',
            answer:
                'As soon as you sail more than 6 miles offshore, you are obliged to keep a logbook on your boat and to accompany it with a logbook that lists all the dates of the tests carried out on the boat (evacuation of the boat, engine, etc.). Your logbook is considered an act of faith in the event of a lawsuit.',
        },
        {
            question: 'What should it contain?',
            answer:
                'However, the content remains rather evasive according to the regulation, dated 11 March 2008: "a logbook containing the relevant elements for monitoring the navigation and safety of the vessel". Like a sailor\'s logbook, you are obliged, according to the many articles established, to keep your logbook up to date each time you set sail. You must list in it : - All that surrounds the weather conditions; - All the conditions of your nautical environment, with tide conditions, speed, course, sea state, as well as geographical coordinates; - Your air environment, specifying the state of the sky, the strength of the wind, and the barometer indication; - Your crew, i.e. the skipper and the crew members who assist him; - Finally, of course, the time at which you leave the port and the date, without forgetting to specify your destination and the potential stopovers made. Keeping your navigation logbook must therefore allow you to ensure the safety of the passengers on the boat, as well as to know the exact number of crew.',
        },
        {
            question: 'How to write a logbook?',
            answer:
                'The logbook is a supplement to the logbook. It provides additional information concerning navigation. If you are used to going to sea, you know without a doubt that it can become very changeable and capricious in a few hours. To prevent any incidents, it is advisable to take regular notes every 3 hours. In this way, you can easily spot a noticeable change in your environment (weather, technical, tide, etc.),',
        },
    ],
}

export default AppFAQ
