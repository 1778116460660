import React from 'react'
import ReactGA from 'react-ga'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
import ReactPixel from 'react-facebook-pixel'
import BoatOnComponent from '../common/BoatOnComponent'

import Navigation from '../common/Navigation'
import Footer from '../common/Footer'
import HomeHead from './Head/HomeHead'

import styles from './Styles/HomePageCss'
import dictionary from './Dictionary/HomePageDico'
import theme from './Styles/HomeTheme'
import Content from "./Content";
import SideNavigation from '../common/SideNavigation'

class HomePage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            hideCategory: true,
            mobileNav: false
        }
        this.openMenu = this.openMenu.bind(this)
        this.initializeReactGA()
        this.handleScroll = this.handleScroll.bind(this)
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/home')
    }


  openMenu(){
      this.setState(prev => ({mobileNav: !prev.mobileNav}))
      console.log(this.state.mobileNav)
   
  }

    handleScroll(value) {
        this.setState({ hideCategory: value })
    }

    render() {
        const { classes } = this.props
        const { hideCategory } = this.state
        let showMenu = this.openMenu

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        return (
            <div className={classes.root}>
                <ThemeProvider theme={theme}>
                {this.state.mobileNav ?
                 <SideNavigation/> : <></>}
                    <Navigation
                        hideCategory={hideCategory}
                        onScroll={this.handleScroll}
                        location={this.props.location}
                        showMenu={showMenu}
                    >
                        <HomeHead />
                    </Navigation>
                    <Content/>
                    <Footer />
                </ThemeProvider>
            </div>
        )
    }
}

export default withStyles(styles)(HomePage)
