import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { PrivateRoute } from './components/common/PrivateRoute'

import { history } from './helpers'
import { alertActions, userActions } from './actions'
import theme from './helpers/customTheme'
import HomePage from './components/HomePage/HomePage'
import AppLanguage from './constants/AppLanguage'
import AppRoute from './constants/AppRoute'
import { LocalizedRouter, MultiLangualSwitch, appStrings } from './languages'
import '../src/App.css'


class App extends Component {
    constructor(props) {
        super(props)

        const { dispatch } = this.props
        dispatch(userActions.init())

        history.listen((location, action) => {
            dispatch(alertActions.clear())
        })
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <LocalizedRouter
                    RouterComponent={Router}
                    languages={AppLanguage}
                    appStrings={appStrings}
                    history={history}
                >
                    <div>
                        <MultiLangualSwitch
                            errorRedirection={AppRoute.Error404}
                        >
                            <Route
                                exact
                                route={AppRoute.Root}
                                component={HomePage}
                            />
                            <Route exact route={AppRoute.Home}>
                                <Redirect to="/" />
                            </Route>
                        </MultiLangualSwitch>
                    </div>
                </LocalizedRouter>
            </MuiThemeProvider>
        )
    }
}

export default connect()(App)
