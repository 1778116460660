import {
    FETCH_DATAS_BEGIN,
    FETCH_DATAS_SUCCESS,
    FETCH_DATAS_ERROR,
} from '../actions/api.actions'

const initialState = {
    loading: true,
    error: null,
    new: [],
    current: [],
}

export default function fetch(prevState = initialState, action) {
    switch (action.type) {
        case FETCH_DATAS_BEGIN:
            return {
                ...prevState,
                loading: true,
                [action.url]: [],
                error: null,
            }
        case FETCH_DATAS_SUCCESS:
            return {
                ...prevState,
                loading: false,
                [action.url]: action.payload.datas,
            }
        case FETCH_DATAS_ERROR:
            return {
                ...prevState,
                loading: false,
                error: action.payload.error,
                [action.url]: [],
            }
        default:
            return prevState
    }
}
