import { white } from "material-ui/styles/colors";

export default theme => ({
    RowBreadCrumbs: {
        width: 'auto',
        marginTop: '25px',
    },
    RowFooter: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#D0021B',
        width: '100%',
        tableLayout: 'fixed',
        bottom: 0,
        paddingTop: '5px',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        overflow: 'hidden',
        zIndex: 0,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    column: {
        minWidth: '290px',
        maxWidth: '290px',
        maxHeight: '360px',
        minHeight: '360px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    footerLinkText: {
        display: 'block',
        textDecoration: 'none',
        color: '#fff',
        margin: '6px 0',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    colonne: {
        flex: '1 1 180px',
        textAlign: 'left',
        margin: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    lastColonne: {
        display: 'inline-block',
        flex: '1 1 200px',
        textAlign: 'left',
        margin: '15px',
    },
    title: {
        position: 'relative',
        fontWeight: 400,
        fontSize: '18px',
        color: '#fff',
        margin: '28px 0 18px 0',
        textTransform: 'uppercase',
        textDecoration: 'none',
        '&::before': {
            content: `''`,
            position: 'absolute',
            bottom: '-2px',
            width: '60px',
            height: '2px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            left: '0',
        },
    },
    more: {
        fontSize: '11px',
        fontWeight: 300,
        color: '#fff',
    },
    text: {
        color: '#fff',
        fontSize: '15px',
        fontWeight: 300,
        width: '180px',
        marginBottom: '3px',
        textDecoration: 'none',
    },
    boatonText: {
        color: '#fff',
        fontSize: '11px',
        fontWeight: 300,
    },
    applications: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        lineHeight: '1.5',
    },
    image: {
        width: '100px',
        margin: '0 3px',
    },
    icon: {
        margin: '0 5px',
        color: '#fff',
    },
    bottomSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    logoPartner: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '5px',
    },
    linkAndApps: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '64px',
    },
    content: {
        marginLeft: '20%',
        marginRight: '20%',
        fontFamily: 'Roboto'
    },
    formInput: {
        display: 'block',
        marginLeft: '40%',
        marginRight: 'auto',
        marginTop: '1%',
        fontSize: '16px'
    },
    textField: {
        marginRight: '10px',
        marginLeft: '10px',
        width: 'calc(100% - 20px)',
    },
    customLinks: {
        textDecoration: "none",
        padding: "2px",
        color: "#FFF",

    },
    
})
