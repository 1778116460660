export default theme => ({
    root: {
        marginTop: '-38px',
        marginLeft: '-20px',
        marginRight: '-20px',
        marginBottom: '-50px',
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-10px',
            marginRight: '-10px',
        },
    },
    iconButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    drawerPaper: {
        position: 'fixed',
        marginTop: '94px',
        backgroundColor: '#303F9F',
        whiteSpace: 'inherit',
        width: '240px',
        minHeight: '100vh',
        height: 'calc(100% + 50px)',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '52px',
        },
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        whiteSpace: 'noWrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbar: {
        position: 'relative',
    },
    toolbarRight: {
        marginRight: 0,
        marginLeft: 'auto',
    },
    button: {
        color: '#fff',
        marginLeft: '5px',
    },
    content: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 58px)',
            marginLeft: '60px',
        },
        marginLeft: '73px',
    },
    navLinks: {
        textDecoration: "none",
        color: "black",
        cursor: "pointer", 
    },

})
