import { FILTER_ACTION } from '../constants'

const initialState = {
    Price: [-1, -1],
    Width: [-1, -1],
    Length: [-1, -1],
    Height: [-1, -1],
    startDate: null,
    endDate: null,
    mapStatus: true,
    rowsPerPage: 20,
    page: 0,
    parkingIdHovered: null,
    address: null,
    mensualisation: false,
}

export default function filter(prevState = initialState, action) {
    switch (action.type) {
        case FILTER_ACTION:
            return {
                ...prevState,
                ...action.payload,
            }
        default:
            return prevState
    }
}
