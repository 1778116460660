import { userService } from '../services/user.service'

export const FETCH_DATAS_BEGIN = 'FETCH_DATAS_BEGIN'
export const FETCH_DATAS_SUCCESS = 'FETCH_DATAS_SUCCESS'
export const FETCH_DATAS_ERROR = 'FETCH_DATAS_ERROR'

export const POST_DATA_BEGIN = 'FETCH_DATAS_BEGIN'
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS'
export const POST_DATA_ERROR = 'POST_DATA_ERROR'

export default {
    fetchData,
    postData,
}

export function fetchData(
    url,
    typeFetch,
    method,
    body = null,
    timeout = null,
    fetchCallback = json => {},
) {
    const option = {
        method,
        headers: userService.getAuthHeader(),
    }
    if (method != 'GET') option.body = body

    return dispatch => {
        dispatch({ type: FETCH_DATAS_BEGIN })

        if (timeout) {
            const controller = new AbortController()
            setTimeout(() => controller.abort(), timeout)
            option.signal = controller.signal
        }

        return fetch(url, option)
            .then(handleErrors)
            .then(res => {
                return res.json()
            })
            .then(json => {
                fetchCallback(json)
                dispatch(fetchDatasSuccess(json, typeFetch, method))
                return json
            })
            .catch(error => {
                console.log(error)
                fetchCallback(null, error)
                dispatch(fetchDatasError(error))
                return { success: false }
            })
    }

    function handleErrors(response) {
        if (!response.ok) {
            throw response
        }
        return response
    }

    function fetchDatasSuccess(datas, typeFetch, method) {
        return {
            type: FETCH_DATAS_SUCCESS,
            payload: { datas },
            url: typeFetch,
            urlType: method,
        }
    }

    function fetchDatasError(error) {
        return {
            type: FETCH_DATAS_ERROR,
            payload: { error },
        }
    }
}

export function fetchCache(typeFetch, json, fetchCallback) {
    return dispatch => {
        dispatch({ type: FETCH_DATAS_BEGIN })

        return new Promise((resolve, reject) => {
            console.log(json)
            resolve(json)
        })
            .then(json => {
                fetchCallback(json)
                dispatch(fetchDatasSuccess(json, typeFetch, 'GET'))
                return json
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchDatasError(error))
                return { success: false }
            })
    }

    function fetchDatasSuccess(datas, typeFetch, method) {
        return {
            type: FETCH_DATAS_SUCCESS,
            payload: { datas },
            url: typeFetch,
            urlType: method,
        }
    }

    function fetchDatasError(error) {
        return {
            type: FETCH_DATAS_ERROR,
            payload: { error },
        }
    }
}

export function postData(url, postData) {
    const option = {
        method: 'POST',
        headers: userService.getAuthHeader(),
        body: postData,
    }

    return dispatch => {
        return fetch(url, option)
            .then(res => res.json())
            .then(json => {
                dispatch(postDatasSuccess(json))
                return json
            })
            .catch(error => dispatch(postDatasError(error)))
    }

    function postDatasSuccess(payload) {
        return {
            type: POST_DATA_SUCCESS,
            payload,
        }
    }

    function postDatasError(error) {
        return {
            type: POST_DATA_ERROR,
            error,
        }
    }
}
