import {
    FETCH_DATAS_ADDRESS_BEGIN,
    FETCH_DATAS_ADDRESS_SUCCESS,
    FETCH_DATAS_ADDRESS_ERROR,
} from '../actions/address.actions'

const initialState = {
    loading: true,
    error: null,
    address: {},
}

export default function fetchAddress(prevState = initialState, action) {
    switch (action.type) {
        case FETCH_DATAS_ADDRESS_BEGIN:
            return {
                ...prevState,
                loading: true,
                address: [],
                error: null,
            }
        case FETCH_DATAS_ADDRESS_SUCCESS:
            return {
                ...prevState,
                loading: false,
                address: action.payload.datas.features,
            }
        case FETCH_DATAS_ADDRESS_ERROR:
            return {
                ...prevState,
                loading: false,
                error: action.payload.error,
                address: [],
            }
        default:
            return prevState
    }
}
