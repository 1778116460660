const AppMeta = {
    fr: {
        'routes.root': {
            title: 'Stockage professionnel sans engagement avec stockon partout en France',
            description:
                'Entrepôt de stockage professionnel à louer dans la France entière pour marchandises industrielle, débords logistiques, stockage d\'urgence... Location sans engagement avec StockOn.',
            type: '',
            image: '',
        }
    },
    en: {
      'routes.root': {
        title: 'Stockage professionnel sans engagement avec stockon partout en France',
        description:
          'Entrepôt de stockage professionnel à louer dans la France entière pour marchandises industrielle, débords logistiques, stockage d\'urgence... Location sans engagement avec StockOn.',
        type: '',
        image: '',
      }
    },
}

export default AppMeta
