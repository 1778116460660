import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

import Typography from "@material-ui/core/Typography";

import BoatOnComponent from "./BoatOnComponent";

import { connect } from "react-redux";

import styles from "./Styles/FooterCss";
import { Button } from "@material-ui/core";
import { fetchData } from "../../actions/api.actions";
import { API_URL } from "../../services/config.service";
import TextField from "@material-ui/core/TextField";
import Grid from "@mui/material/Grid";
import { borderRadius, padding } from "@mui/system";
import imgfooter from "../../images/footImg.png";

import dictionary from "../HomePage/Dictionary/FooterDictionary";


class Footer extends BoatOnComponent {
  constructor(props) {
    super(props);
    console.log(props);
    console.log("test");
    this.dictionary = dictionary;
    this.parameters = props.parameters;
    this.state = {
      name: "",
      firstname: "",
      mail: "",
      department: "",
      phone: "",
    };
    this.sendMail = this.sendMail.bind(this);
  }
  handleChange(value, key) {
    this.setState({ [key]: value });
  }

  sendMail() {
    const data = new FormData();
    if (
      this.state.mail !== "" &&
      this.state.firstname !== "" &&
      this.state.name !== "" &&
      this.state.department !== "" &&
      this.state.phone !== ""
    ) {
      data.append("name", this.state.name);
      data.append("firstname", this.state.firstname);
      data.append("mail", this.state.mail);
      data.append("department", this.state.department);
      data.append("phone", this.state.phone);
      console.log(this.props);
      this.props.dispatch(
        fetchData(API_URL + "/stockon/sendmail.php", "mail", "POST", data)
      );
      alert(
        "C'est noté ! Nous avons bien recu votre demande et reviendrons vers vous si nous avons un espace correspondant à votre demande."
      );
    } else {
      alert("Merci de renseigner l'intégralité des champs du formulaire");
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{  }}>
        <div style={{position: "relative" , top: 60}} className={classes.RowFooter}></div>
        <div className={classes.content} style={{ minHeight: 500 , position: "relative" , top: 70}}>
          <p>
            Vous souhaitez être avertis dès la publication d'un nouvel espace de
            stockage ? Laissez-nous votre email !
          </p>
          <div>
            <TextField
              onChange={this.handleChange}
              type="text"
              id="mail"
              margin="dense"
              variant="outlined"
              placeholder="Nom"
              className={classes.formInput}
              value={this.state.name}
              onChange={(e) => {
                this.handleChange(e.target.value, "name");
              }}
            />
            <TextField
              onChange={this.handleChange}
              margin="dense"
              variant="outlined"
              type="text"
              id="mail"
              placeholder="Prénom"
              className={classes.formInput}
              value={this.state.firstname}
              onChange={(e) => {
                this.handleChange(e.target.value, "firstname");
              }}
            />
            <TextField
              onChange={this.handleChange}
              margin="dense"
              variant="outlined"
              type="text"
              id="mail"
              placeholder="Téléphone"
              className={classes.formInput}
              value={this.state.phone}
              onChange={(e) => {
                this.handleChange(e.target.value, "phone");
              }}
            />
            <TextField
              onChange={this.handleChange}
              margin="dense"
              variant="outlined"
              type="text"
              id="mail"
              placeholder="E-mail"
              className={classes.formInput}
              value={this.state.mail}
              onChange={(e) => {
                this.handleChange(e.target.value, "mail");
              }}
            />
            <TextField
              onChange={this.handleChange}
              margin="dense"
              variant="outlined"
              type="text"
              id="mail"
              placeholder="Ville recherchée"
              className={classes.formInput}
              value={this.state.department}
              onChange={(e) => {
                this.handleChange(e.target.value, "department");
              }}
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1%",
              }}
              onClick={this.sendMail}
            >
              <span className={classes.textSearchBar}>ENVOYER</span>
            </Button>
            <br />
            <br />
          </div>
        </div>
        <Grid
          container
          // spacing={{ xs: 3, md: 3 }}
          rowSpacing={4}
          columnSpacing={{ xs: 10, sm: 10, md: 10 }}
          style={{
            columns: 3,
            background: "#cf011b",
            color: "#FFFF",
            justifyContent: "center",
            paddingBottom: "2%"
          }}
        >
          <Grid
            item
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <div>
              <h2>
                <span style={{paddingBottom: 4}}  className="line">
                  LES
                </span>&nbsp;SERVICES STOCKON
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <a style={{paddingBottom: 6}}
                  className={classes.customLinks}
                  href="https://info.stockon.fr/tout-savoir-sur-le-stockage-de-camping-car/"
                >
                  {this.displayText("camping")}
                </a>
                <a style={{paddingBottom: 6}}
                  className={classes.customLinks}
                  href="https://www.boaton.fr/fr/chercher-un-parking"
                >
                  {this.displayText("boat")}
                </a>
                <a style={{paddingBottom: 6}}
                  className={classes.customLinks}
                  href="https://info.stockon.fr/tout-savoir-sur-le-stockage-sans-engagement-pour-les-entreprises/"
                >
                  {this.displayText("entreprise")}
                </a>
                <a style={{paddingBottom: 6}}  className={classes.customLinks} href="">
                  {this.displayText("status")}
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
              }}
            >
              <h2 style={{paddingTop: 25}}><span style={{paddingBottom: 4}} className="line">PROF</span>ESSIONNEL </h2>
              <a className={classes.customLinks} href="">
                Je suis pro
              </a>
              <a className={classes.customLinks} href="">
                {this.displayText("button")}
              </a>
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              marginLeft: 70
            }}
          >
            <div style={{display: "flex", flexDirection:"column" ,justifyContent: "space-between"}}>
              <h2> <span style={{paddingBottom: 4}} className="line">A &nbsp;PR</span>OPOS</h2>
              <div style={{display:"flex", flexDirection: "column"}}>
              <a
                className={classes.customLinks}
                href="https://info.stockon.fr/nous-contacter/"
              >
                NOUS CONTACTER
              </a>
              <a
                className={classes.customLinks}
                href="https://info.boaton.fr/cgv"
              >
                CGV
              </a>
              </div>
            </div>
            <div>
              <div style={{width: 270, paddingBottom: 20}}>
              <p className="line"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                  justifyContent:"space-between",
                  paddingTop: 20,
                }}
              >

                {this.displayText("social")} &nbsp;

              </p>
              </div>

              <div style={{
                display: "flex",
                placeItems: "center"
              }}>
              <a href="https://www.facebook.com/stockonpro">
                <FaFacebookF
                  style={{
                    background: "white",
                    color: "#D0251A",
                    height: 20,
                    width: 20,
                    padding: 3,
                    margin: 3,
                    borderRadius: 100,
                  }}
                />
              </a>
              <a href="https://www.youtube.com/channel/UCoEeZgYsy8oxq9ksDZuOd7A">
                <FaYoutube
                  style={{
                    background: "#cf011b",
                    color: "#fff",
                    height:25,
                    width: 80,
                    padding: 3,
                    margin: 3,
                    borderRadius: 100,
                  }}
                />
              </a>
              </div>
              <p style={{paddingTop: 10}}>Copyright, &copy; 2022 Stockon | propulsé par Stockon</p>
            </div>
          </Grid>
          <Grid item>
            <h2><span style={{paddingBottom: 4}} className="line">NOS</span> PARTENAIRES</h2>
            <img style={{ width: 300 }} src={imgfooter} alt="" />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(Footer));
