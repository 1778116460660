import { alertConstants } from '../constants'

export default function alert(prevState = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                ...prevState,
                type: 'alert-success',
                message: action.message,
            }
        case alertConstants.ERROR:
            return {
                ...prevState,
                type: 'aler-danger',
                message: action.message,
            }
        case alertConstants.CLEAR:
            return {}
        default:
            return prevState
    }
}
