import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'

import styles from '../Styles/HomeHead'
import dictionary from '../Dictionary/HomeHeadDico'

import ParkingSearch from './ParkingSearch'

class HomeHead extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}
    }

    render() {
        const { classes } = this.props

        return (
            <div style={{position:"relative", top: 80}} className={classes.root}>
                <div className={classes.parkingSearchSection}>
                    <ParkingSearch></ParkingSearch>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(HomeHead)
