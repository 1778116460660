let appConfig = {
    API_URL: 'https://api.boaton.fr',
    LS_USER_KEY: 'boaton_public_user',
    STRIPE_PUBLIC_KEY: 'stripe_public_key',
    FB_PIXEL_KEY: 'facebook_pixel_id',
    GA_KEY: 'google_analytics_key',
}



try {
    let configFile = require('../config.json')
    console.log('[config] a configuration file was found')
    appConfig = Object.assign(appConfig, configFile)
} catch (ex) {
    console.log('[config] no config.json, ignoring')
}

export const {
    API_URL,
    LS_USER_KEY,
    STRIPE_PUBLIC_KEY,
    FB_PIXEL_KEY,
    GA_KEY,
} = appConfig
