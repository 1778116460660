import React from 'react'
import { withStyles} from '@material-ui/core/styles'
import BoatOnComponent from '../common/BoatOnComponent'


import styles from './Styles/ContentCss'
import dictionary from './Dictionary/HomePageDico'
import {Button} from "@material-ui/core";

class Content extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    goBoatonParking(){
        window.location.assign('https://www.boaton.fr/fr/chercher-un-parking');
    }

    render() {
        const { classes } = this.props
        return (
            <div   className={classes.content}>
               <h2 className={classes.h2}>{this.displayText('title1')}</h2>
                <p className={classes.p}>{this.displayText('content1')}</p>
                <h2 className={classes.h2}>{this.displayText('title2')}</h2>
                <p className={classes.p}>{this.displayText('content2')}
                   <br/><br/>{this.displayText('content3')}
                    <ul>
                        <li>{this.displayText('content4')}</li>
                        <li>{this.displayText('content5')}
                            <ul>
                                <li>{this.displayText('content6')}</li>
                                <li>{this.displayText('content7')}</li>
                                <li>{this.displayText('content8')}</li>
                            </ul>
                        </li>
                    </ul>
                </p>
                <h2 className={classes.h2}>{this.displayText('title3')}</h2>
                <p className={classes.p}>{this.displayText('content9')}<a href="https://www.boaton.fr/fr/chercher-un-parking">{this.displayText('content10')}</a>{this.displayText('content11')} </p>
                <h2 className={classes.h2}>{this.displayText('title4')}</h2>
                <p className={classes.p}>{this.displayText('content12')}<a href="https://www.boaton.fr/fr/chercher-un-parking">{this.displayText('content10')}</a></p>
                <h2 className={classes.h2}>{this.displayText('title5')}</h2>
                <p className={classes.p}>{this.displayText('content13')} </p>
                <h2 className={classes.h2}>{this.displayText('title6')}</h2>
                <p className={classes.p}>{this.displayText('content14')}
                {this.displayText('content15')}<a href="https://info.boaton.fr/contactez-nous">{this.displayText('content16')}</a>. </p><br/>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{display: 'block',
                        margin: 'auto'}}
                    onClick={this.goBoatonParking}
                >
                    <span className={classes.textSearchBar}>{this.displayText('button')}</span>
                </Button><br/><br/>

            </div>
        )
    }
}

export default withStyles(styles)(Content)
