import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'

import styles from '../Styles/HomeHead'
import dictionary from '../Dictionary/HomeHeadDico'

import Container from '@material-ui/core/Container'
import homeheadimage from '../../../images/photos/fond.jpg'
import { Grid, Button } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import { filterAction } from '../../../actions'
import { connect } from 'react-redux'
import { history } from './../../../helpers'
import AppRoute from '../../../constants/AppRoute'
import SearchCityBar from './SearchCityBar'

class ParkingSearch extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            searchValue: null,
            cityValue: null,
        }

        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleResults = this.handleResults.bind(this)
        this.defaultSearch = this.defaultSearch.bind(this)
        this.citySearch = this.citySearch.bind(this)
        this.dispatch = props.dispatch
    }

    handleSearchChange(valueChange) {
        if (valueChange) {
            this.setState({
                searchValue: valueChange.lastValue,
            })
        }
    }

    handleSearch(address) {
        if (address) {
            this.setState({
                cityValue: address,
            })
        }
        this.dispatch(filterAction({ address }))
        this.historyPush(history, AppRoute.ParkingSearchPage)
    }

    handleResults(json) {
        if (json.results && json.results.length > 0) this.results = json.results
    }

    defaultSearch() {
        window.location.assign('https://boaton.fr/chercher-un-parking'); //lien en remplacer par localhost pour test
    }

    citySearch(city) {
        window.location.assign('http://boaton.fr/chercher-un-parking?'+ city);
    }


    render() {
        const { classes } = this.props
        const { searchValue } = this.state

        return (
            <>
                <div className={classes.backimagecontainer}>
                    <img
                        src={homeheadimage}
                        alt="home"
                        title="home"
                        srcSet=""
                        className={classes.backimage}
                    />
                </div>
                <Container maxWidth="lg" className={classes.parkingSearch}>
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} md={8}>
                            <Typography variant="h2" component="h1">
                                {this.displayText('findPlace')}
                            </Typography>
                            <Typography variant="subtitle2" component="h2">
                                {this.displayText('moreThan')}
                            </Typography>
                            <div className={classes.homeSearchBarBox}>
                                <SearchCityBar
                                    className={classes.homeSearchBar}
                                    textFieldClass={classes.homeSearchBarField}
                                    onValueChange={this.handleSearchChange}
                                    onResults={this.handleResults}
                                    onSearch={this.handleSearch}
                                    addressValue={searchValue}
                                    searchType="city"
                                    placeholder={this.displayText('where')}
                                    autoCompletion={null}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.defaultSearch()
                                        }
                                    }}
                                />
                                {/* <InputBase
                                        className={classes.homeinput}
                                        placeholder="Où cherchez vous une place ?"
                                    /> */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={this.defaultSearch}
                                >
                                    <SearchIcon
                                        className={classes.iconSearchBar}
                                    />
                                    <span className={classes.textSearchBar}>
                                        {this.displayText('search')}
                                    </span>
                                </Button>
                            </div>
                            <div className={classes.buttonCity}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.city}
                                    style={{ marginLeft: '0' }}
                                    onClick={() => {
                                        this.citySearch('Bordeaux')
                                    }}
                                >
                                    <span className={classes.textSearchBar}>
                                        Bordeaux
                                    </span>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.city}
                                    onClick={() => {
                                        this.citySearch('Toulouse')
                                    }}
                                >
                                    <span className={classes.textSearchBar}>
                                        Toulouse
                                    </span>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.city}
                                    onClick={() => {
                                        this.citySearch('LaRochelle')
                                    }}
                                >
                                    <span className={classes.textSearchBar}>
                                        La rochelle
                                    </span>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.city}
                                    style={{ marginRight: '0' }}
                                    onClick={() => {
                                        this.citySearch('Marseille')
                                    }}
                                >
                                    <span className={classes.textSearchBar}>
                                        Marseille
                                    </span>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
}

function SearchIcon(props) {
    return (
        <svg
            width={17.856}
            height={17.856}
            viewBox="0 0 17.856 17.856"
            {...props}
        >
            <g
                data-name="Icon feather-search"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            >
                <path
                    data-name="Trac\xE9 38"
                    d="M14.726 7.863A6.863 6.863 0 117.863 1a6.863 6.863 0 016.863 6.863z"
                />
                <path data-name="Trac\xE9 39" d="M16.442 16.442L12.71 12.71" />
            </g>
        </svg>
    )
}

export default connect()(withStyles(styles)(ParkingSearch))
