import React, { useState } from "react";
import Typography from '@material-ui/core/Typography'
import Hoverpopup from '../common/Hoverpopup'

import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import { Container, Grid, ButtonGroup, Button } from "@material-ui/core";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tel from "@material-ui/icons/LocalPhone";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import styles from "./Styles/NavivationCss";
import logo from "../../images/Logo_StockOn.png";
import BoatOnComponent from "./BoatOnComponent";
import { LocalizedLink, LocalizedSwitcher } from "../../languages";
import AppRoute from "./../../constants/AppRoute";
import { getCurrentRouteKey } from "../../languages/LocalizerUtils";
import Box from "@mui/material/Box";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import dictionary from "../HomePage/Dictionary/NavigationDico";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Menu from "@mui/material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { borderRadius, fontSize } from "@mui/system";
import { AiOutlineMenu } from "react-icons/ai";
const timeoutLength = 500;

class Navigation extends BoatOnComponent {
  constructor(props) {
    super(props);
    this.dictionary = dictionary;
    this.location = props.location;

    this.state = {
      hideCategory: props.hideCategory ? props.hideCategory : false,
      anchorEl: null,
      anchorElTwo: null,
      mouseOverButton: false,
      mouseOverMenu: false,
      mouseOverButtonTwo: false,
      mouseOverMenuTwo: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hideCategory !== this.state.hideCategory) {
      this.setState({ hideCategory: this.props.hideCategory });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  renderSecondPart() {
    const { classes, user } = this.props;

    return <div className={classes.secondPart}></div>;
  }


  handleClick(event) {
    if (this.state.anchorEl !== event.currentTarget) {
      this.setState({ anchorEl: event.currentTarget });
    }
  }

  handleClose = (event) => {
    if (this.state.anchorEl !== null) {
      this.setState({ anchorEl: null });
    }
  };

  enterButton = () => {
    this.setState({ mouseOverButton: true });
  };

  leaveButton = () => {
    this.setState({ mouseOverButton: false });
  };

  enterMenu = () => {
    this.setState({ mouseOverMenu: true });
  };

  leaveMenu = () => {
    this.setState({ mouseOverMenu: false });
  };

  handleClickTwo(event) {
    this.setState({ anchorElTwo: event.currentTarget });
  }

  handleCloseTwo(event) {
    this.setState({ anchorElTwo: null });
  }

  enterButtonTwo = () => {
    this.setState({ mouseOverButtonTwo: true });
  };

  leaveButtonTwo = () => {
    setTimeout(() => {
      this.setState({ mouseOverButtonTwo: false });
    }, timeoutLength);
  };

  enterMenuTwo = () => {
    this.setState({ mouseOverMenuTwo: true });
  };

  leaveMenuTwo = () => {
    setTimeout(() => {
      this.setState({ mouseOverMenuTwo: false });
    }, timeoutLength);
  };

  renderDependingOnSize() {
    const { classes, useTheme, showMenu } = this.props;


    let servicesLinks = [
    { name: this.displayText('entreprise'),
      link: 'https://info.stockon.fr/tout-savoir-sur-le-stockage-sans-engagement-pour-les-entreprises/'
    },
    {
      name: this.displayText("camping"),
      link: "https://info.stockon.fr/tout-savoir-sur-le-stockage-de-camping-car/"
    },
    {
      name: this.displayText("boat"),
      link: "https://www.boaton.fr/fr/chercher-un-parking"
    }

    ]

    let aboutLinks = [
      {
        name: this.displayText("knowledge"),
        link: "https://info.stockon.fr/tout-savoir-sur-loffre-packagee-tout-en-un-de-stockon-fr/"
      },
      {
        name: this.displayText("we"),
        link: "https://info.stockon.fr/qui-sommes-nous/"
      }

      ]


    let anchorEl = this.state.anchorEl;
    let anchorElTwo = this.state.anchorElTwo;

    const open = Boolean(anchorEl);
    const openTwo =
      this.state.mouseOverButtonTwo || this.state.mouseOverMenuTwo;



    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar sx={{ bgcolor: "#FFFFFF" }}>
          <div className={classes.entete}>
            <p
              className="customp"
              className={classes.entete}
              style={{
                fontSize: 18,
                fontWeight: 400,
                paddingLeft: 25,
                fontFamily: "Roboto, sans serif",
              }}
            >
              {this.displayText("us")} &nbsp; &nbsp;{" "}
            </p>
          </div>
          <Toolbar
            style={{
              position: 'relative',
              minHeight: 80,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div style={{ flex: 1 }}>
              <img src={logo} style={{ maxWidth: 200 }} alt="" />
            </div>

            <div
              sm={{ display: "none" }}
              style={{
                display: "flex",
                flex: 1.3,
                justifyContent: "flex-end",
                placeItems: "center",
              }}
            >
              <div style={{
                display: "flex",
                width: 780,
                justifyContent: "space-between",
                placeItems: "center",
              }}>
              <Hoverpopup top={100} left={700} style={classes.navLinks} text={this.displayText("services")} data={servicesLinks}/>
                <Hoverpopup top={100} left={900} style={classes.navLinks} text={this.displayText("about")} data={aboutLinks}/>
              <a
                className={classes.navLinks}
                href="https://info.stockon.fr/je-suis-pro/"
                color="inherit"
              >
                {this.displayText("status")}
              </a>
              <a
                className={classes.navLinks}
                style={{ paddingRight: 20}}
                href="https://info.stockon.fr/faq/"
                color="inherit"
              >
                FAQ
              </a>
              <Button
                href="https://www.boaton.fr/fr/louer-mon-emplacement"
                style={{
                  backgroundColor: "#cf011b",
                  color: "#fff",
                  width: 300,
                  paddingTop: 3,
                  paddingBottom: 3,
                  fontWeight: 500,
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 100,
                  fontSize: 16,
                }}
                className={classes.navLinks}
              >
                {this.displayText("button")}
              </Button>

              <AiOutlineMenu
                className={classes.hamburger}
                onClick={() => this.props.showMenu()}
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
              />
              </div>

            </div>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classNames(classes.appBar)}>
          <AppBar color="inherit" position="sticky">
            <Toolbar disableGutters>{this.renderDependingOnSize()}</Toolbar>
          </AppBar>
        </div>

        <main className={classes.content}>
          <div className={classes.toolbar}>{this.props.children}</div>
        </main>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
  };
}

const NavigationConnect = connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Navigation)
);

export default NavigationConnect;
