export default {
    title1: {
        FR: 'StockOn : le spécialiste du stockage sans engagement pour les entreprises',
        EN: 'StockOn: storage of motorhomes made easy',
    },
    content1: {
        FR: 'StockOn est une plateforme mettant en relation des centaines de sites de stockage avec des entreprises de toute taille. Artisans, transporteurs, sociétés d’import-export, de livraison ou groupe industriel, tous nos clients apprécient la simplicité, rapidité et attention que nos équipes portent à leur demande. Pour arriver à ce résultat, nous mettons à disposition : ',
        EN: 'StockOn is a platform that connects hundreds of storage sites with companies of all sizes. Craftsmen, transporters, import-export companies, delivery companies or industrial groups, all our customers appreciate the simplicity, speed and attention that our teams pay to their requests. To achieve this result, we provide : '
    },
  content1_2: {
    FR: ' une équipe de conseillers dédiés pour répondre à toutes vos questions. Elément déterminant pour nous, le conseiller que vous avez au téléphone suit votre projet et reste votre point de contact central tout au long du traitement de votre demande',
    EN: ' a dedicated team to answer all your questions. A key element for us is that the person you have on the phone will follow your project and remain your central point of contact throughout the process',
  },
  content1_1: {
    FR: ' une plate-forme optimisée pour trouver et réserver la surface de stockage qu’il vous faut en quelques clics ',
    EN: ' an optimised platform for finding and booking the storage space you need in just a few clicks'
  },
    title2: {
        FR: 'StockOn est aussi un dénicheur de trésor',
        EN: 'StockOn is also a treasure hunter',
    },
    content2: {
        FR: 'Vous ne trouvez pas votre bonheur parmi les milliers de m2 d’entrepôts disponibles ? Vous avez un cahier des charges particulier ou souhaitez obtenir des propositions personnalisées ? Nous avons mis sur pied une équipe spéciale qui se mettra en chasse pour trouver l’entrepôt qu’il vous faut. Ecrivez-nous à fred@stockon.fr en indiquant votre numéro de téléphone et nous trouverons un local ou espace de stockage adapté.',
        EN: 'You can\'t find what you\'re looking for among the thousands of square meters of warehouse space available? Do you have special constraints or would you like to receive personalised proposals? We have set up a special team that will be on the hunt to find the warehouse you need. Write to us at fred@stockon.fr with your phone number and we will find a suitable storage site or space. ',
    },
    title3: {
        FR: 'Le stockage sans contrainte au cœur de la solution StockOn',
        EN: 'Unconstrained storage at the heart of the StockOn solution',
    },
    content3: {
        FR: 'StockOn est né d’un constat simple : les espaces de stockage pour les entreprises n’offrent pas assez de flexibilité. Une entreprise a des besoins changeants et doit pouvoir adapter ses capacités de stockage et logistique dans le temps et l’espace. ',
        EN: 'StockOn was born out of a simple observation: storage space for companies does not offer enough flexibility. A company has changing needs and must be able to adapt its storage and logistics capacities in time and space. ',
    },
    content4: {
        FR: 'StockOn vous permet d’augmenter ou réduire la surface de stockage à tout moment',
        EN: 'StockOn allows you to increase or reduce storage space at any time.',
    },
    content5: {
        FR: 'StockOn vous permet de stocker pour quelques jours ou des années sans contrainte',
        EN: 'StockOn allows you to store for a few days or years without any constraint. ',
    },
    title4: {
        FR: 'Des entrepôts de stockage assurés et sécurisés',
        EN: 'Insured and secure storage warehouses',
    },
    content6: {
        FR: 'Les espaces de stockage ou locaux industriels ont été validés par nos équipes et sont assurés pour garantir la sécurité de vos marchandises. \n' +
          'Vous avez un besoin de protection spécifique ? Contactez-nous et nous vous orienterons vers les bons partenaires pour protéger vos équipements et biens stockés. Nous mettrons à disposition notre vaste réseau pour vous simplifier la vie et assurer votre tranquillité d’esprit. ',
        EN: 'The storage spaces or industrial premises have been validated by our teams and are insured to guarantee the safety of your goods. \n' +
          'Do you have a specific need for protection? Contact us and we will direct you to the right partners to protect your equipment and stored goods. We will make our extensive network available to simplify your life and ensure your peace of mind. ',
    },
    title5: {
        FR: 'Entrepôt industriel, plateforme logistique ou terrain aménageable : des espaces et services variés répondant à tous vos besoins\n',
        EN: 'Industrial warehouse, logistics platform or land: a variety of spaces and services to meet all your needs',
    },
    content7: {
        FR: 'Débord logistique, stock dormant, stock tampon, stockage d’urgence ou temporaire, en vrac, palettisé ou en colis, StockOn propose des solutions pour tous vos besoins. Les espaces de stockage disponibles sont très divers : entrepôt industriel, tunnel de stockage, plateforme logistique, rack de stockage et permettent de stocker des marchandises sur palettes, des fournitures pour la construction, le BTP ou l’isolation, le e-commerce, les industriels, les professionnels de l’import-export ou encore du transport et de la livraison. \n' +
      'Bénéficiez également de nombreuses prestations logistiques dans nos espaces de stockage : manutention, chargement et déchargement de palettes et cartons, préparation de commandes, étiquetage de vos colis, conditionnement et reconditionnement, …',
        EN: 'Logistical overflow, dormant stock, buffer stock, emergency or temporary storage, in bulk, palletised or in parcels, StockOn offers solutions for all your needs. The storage spaces available are very diverse: industrial warehouse, storage tunnel, logistics platform, storage rack and allow you to store goods on pallets, supplies for construction, building and public works or insulation, e-commerce, manufacturers, import-export professionals or transport and delivery professionals. \n' +
          'You can also take advantage of a wide range of logistics services in our storage areas: handling, loading and unloading of pallets and boxes, order preparation, labelling of your parcels, packaging and repackaging, etc...',
    },
    title6: {
        FR: 'Comment trouver un entrepôt pour entreprise à louer facilement ?',
        EN: 'How to find a company warehouse for rent easily?',
    },
   content8: {
        FR: 'Vous pouvez trouver votre espace de stockage idéal directement sur le ',
        EN: 'You can find your ideal storage space directly on the ',
    },
    content9: {
        FR: 'site StockOn',
        EN: 'StockOn website',
    },
    content10: {
        FR: ' et le réserver en quelques minutes en ligne. Vous pouvez aussi nous écrire à fred@stockon.fr pour nous faire part de vos besoins d’entreposage ou de stockage spécifiques.',
        EN: ' and book it online in a few minutes. You can also write to us at fred@stockon.fr about your specific storage or warehousing needs. ',
    },
    title7: {
        FR: 'StockOn propose des entrepôts dans toute la France',
        EN: 'StockOn offers warehouses all around France',
    },
    content11: {
        FR: 'StockOn propose des entrepôts d’Hendaye à Reims et de Monaco à Rennes, en passant par Bordeaux, Toulouse, Perpignan, Narbonne, Montpellier, Marseille, Toulon, Nice, Angoulême, La Rochelle, Nantes ou encore Bourges, Annemasse, Annecy, Nancy ou Paris. ',
        EN: 'StockOn offers warehouses from Hendaye to Reims and from Monaco to Rennes, via Bordeaux, Toulouse, Perpignan, Narbonne, Montpellier, Marseille, Toulon, Nice, Angoulême, La Rochelle, Nantes or Bourges, Annemasse, Annecy, Nancy or Paris. ',
    },
    title8: {
        FR: 'Un besoin ? Une question ? ',
        EN: 'Do you have any need? Any question? ',
    },
    content12: {
        FR: 'Vous avez une question sur un espace de stockage ? Vous êtes bloqué dans le processus de réservation et souhaiteriez de l’aide ? Vous faites face à un imprévu sur place ? Nos équipes sont là pour vous assister et vous aider à trouver une solution. Contactez-nous où que vous soyez au +33 6 78 76 36 98 ou sur ',
        EN: 'You have a question about a storage space? You are stuck in the booking process and would like some help? You are facing an unexpected event on site? Our teams are here to assist you and help you find a solution. Contact us wherever you are at +33 6 78 76 36 98 or on ',
    },
      content13: {
        FR: 'stockon.fr/contactez-nous',
        EN: 'stockon.fr/contact us',
      },
  button: {
    FR: 'TROUVEZ VOTRE ESPACE DE STOCKAGE IDÉAL',
    EN: 'FIND YOUR IDEAL STORAGE SPACE',
  }
}
