export const en = {
    'routes.root': '/',
    'routes.home': '/home',
    'routes.inqueries': '/my-requests',
    'routes.inqueries.insurance': '/my-requests/insurance',
    'routes.inqueries.insurance.details': '/my-requests/insurance/details',
    'routes.inqueries.repairs': '/my-requests/repairs',
    'routes.inqueries.parking': '/my-requests/parking',
    'routes.inqueries.parking.chatbox': '/my-requests/parking/chat',
    'routes.inquery': '/my-request',
    'routes.login': '/login',
    'routes.register': '/register',
    'routes.profile': '/my-profile',
    'routes.404': '/erreur-404',
    'routes.rent-my-parking': '/rent-my-space',
    'routes.messages': '/messages',
    'routes.forget': '/forget',
    'routes.parkingid': '/parking/:id',
    'routes.booking': '/booking',
    'routes.personnal-infos': '/personnalInfos',
    'routes.payment-infos': '/paymentInfos',
    'routes.parkings-infos': '/parkingsInfos',
    'routes.boat-infos': '/boat-infos',
    'routes.le-blog': '/le-blog',
    'routes.forum': '/forum',
    'routes.search-boat-parking': '/search-boat-parking',
    'routes.get-boat-insurance': '/get-boat-insurance',

    'routes.logbook.home': '/logbook',
    'routes.logbook.alerts': '/logbook/my-alerts',
    'routes.logbook.repairs': '/logbook/my-repairs',
    'routes.logbook.informations': '/logbook/my-boat',
    'routes.logbook.exps-a-incm': '/logbook/my-expenses-and-income',
    'routes.logbook.documents': '/logbook/my-documents',
    'routes.logbook.book': '/logbook/my-boaton-book',
    'routes.logbook.pricing': '/logbook/pricing',
}
