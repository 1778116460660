import { userConstants } from '../constants'
import { userService } from '../services/user.service'
import { alertActions } from '.'
import { history } from '../helpers'
export const userActions = {
    init,
    login,
    logout,
    forget,
    signin,
    subscribe,
}

function init() {
    return dispatch => {
        const cached = userService.getFromLS()
        if (cached && cached.User && cached.User.UserID) {
            dispatch({ type: userConstants.LOGIN_SUCCESS, user: cached.User })
        }
    }
}

function login(email, password) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST })
        return userService.login(email, password).then(
            result => {
                console.log('[user] login success')
                console.log(result.User)
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result.User,
                })
            },
            error => {
                console.log('[user] login failed')
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
                return { success: false, user: null }
            },
        )
    }
}

function logout() {
    return dispatch => {
        userService.logout()
        dispatch({ type: userConstants.LOGOUT, user: null })
        console.log('[user] user logged out')
    }
}

function signin(
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    address,
    birthday,
) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST, user: { email } })
        return userService
            .signin(
                email,
                password,
                firstName,
                lastName,
                phoneNumber,
                address,
                birthday,
            )
            .then(
                result => {
                    dispatch({
                        type: userConstants.LOGIN_SUCCESS,
                        user: result.User,
                    })
                    return result.user
                },
                error => {
                    dispatch({ type: userConstants.LOGIN_FAILURE, error })
                    dispatch(alertActions.error(error))
                },
            )
    }
}

function forget(email) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST, user: { email } })
        userService
            .forget(email)
            .then(details => {
                console.log('je test le details ' + details)
                console.log(details)
                if (details.success) {
                    alert('Email envoyé')
                    history.push('/connexion')
                } else {
                    alert('Aucun compte possède cette adresse email')
                }
            })
            .then(error => {
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
            })
    }
}

function subscribe(UserID) {
    return dispatch => {
        return userService.subscribe(UserID).then(
            result => {
                dispatch({
                    type: userConstants.SUBSCRIBE_SUCCESS,
                    user: result.User,
                })
                console.log('subscribe success')
                return result.User
            },
            error => {
                dispatch({ type: userConstants.SUBSCRIBE_FAILURE, error })
                dispatch(alertActions.error(error))
            },
        )
    }
}
