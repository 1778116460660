export default {
    question: {
        FR: 'Que voulez-vous faire ?',
        EN: 'What do you want to do ?',
    },
    storeBoat: {
        FR: 'Stocker mon bateau',
        EN: 'Store my boat',
    },
    storePlace: {
        FR: 'Place de stockage ou hivernage en 1 clic',
        EN: 'Storage place or winter storage in 1 click',
    },
    maintainBoat: {
        FR: 'Entretenir mon bateau',
        EN: 'Maintain my boat',
    },
    quotationPro: {
        FR: 'Les devis des meilleurs pros où que vous soyez',
        EN: 'Quotes from the best professionals wherever you are',
    },
    insureBoat: {
        FR: 'Assurer mon bateau',
        EN: 'Insure my boat',
    },
    savingMoney: {
        FR: "Jusqu'à 40% d'économie sur votre prime",
        EN: 'Up to 40% savings on your premium',
    },
    conditionBoat: {
        FR: "Suivre l'état de mon bateau",
        EN: 'Monitor the condition of my boat',
    },
    logBook: {
        FR: "Carnet d'entretien numérique intelligent",
        EN: 'Intelligent digital maintenance logbook',
    },
    commentary1Title: {
        FR: 'Parking loué à La Rochelle',
        EN: 'Rented car park in La Rochelle',
    },
    commentary2Title: {
        FR: 'Devis moteur Yamaha',
        EN: 'Yamaha Engine Quote',
    },
    commentary3Title: {
        FR: 'Gestion de bateau',
        EN: 'Ship management',
    },
    commentary1: {
        FR:
            'Super app pour stocker mon bateau et toutes les réparations. Très pratique. Bravo aux développeurs!',
        EN:
            'Great app to store my boat and all the repairs. Very handy. Congratulations to the developers!',
    },
    commentary2: {
        FR:
            "Super expérience grâce à Boaton, je recommande vivement ! Devis rapide pour mon moteur Yamaha qui m'a permis de faire une belle économie :-)",
        EN:
            'Great experience thanks to Boaton, I highly recommend it! Quick quote for my Yamaha engine which allowed me to save a lot of money:-)',
    },
    commentary3: {
        FR:
            'Tout simplement la meilleure réponse à la gestion de mon modeste petit bateau. Facile et efficace',
        EN:
            'Quite simply the best answer to the management of my modest little boat. Easy and efficient',
    },
    search: {
        FR: 'Recherche',
        EN: 'Search',
    },
    findPlace: {
        FR: "Trouvez votre espace de stockage idéal",
        EN: 'Find the ideal place for your boat',
    },
    moreThan: {
        FR: 'Plus de 1500 places dans toute la France',
        EN: 'More than 1500 places in France',
    },
    where: {
        FR: 'Où cherchez-vous une place ?',
        EN: 'Where are you searching a place ?',
    },
}
