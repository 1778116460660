export default theme => ({
    root: {
        position: 'relative',
        height: '50vh',
        width: 'calc(100% + 40px)',
        margin: '-122px -20px 0 -20px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            margin: '-122px -10px 0 -10px',
            width: 'calc(100% + 20px)',
            height: 'unset',
        },
    },
    parkingSearchSection: {
        position: 'relative',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flex: 0,
            minHeight: '500px',
        },
    },
    reviewsSection: {
        padding: '20px 0',
        backgroundColor: 'white',
    },
    parkingSearch: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        color: '#fff',
    },
    backimagecontainer: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        '&::before': {
            content: `''`,
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.3)',
        },
    },
    backimage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    homeSearchBarBox: {
        position: 'relative',
        display: 'flex',
        marginTop: '20px',
        marginBottom: '2px',
        alignItems: 'center',
    },
    homeSearchBar: {
        position: 'relative',
        width: '100%',
        marginRight: '10px',
    },
    homeSearchBarField: {
        flex: 1,
        width: '100%',
        height: '100%',
        float: 'left',
        fontSize: '18px',
        borderRadius: '3px',
        '&:focus': {
            outline: 'none',
        },
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        margin: 0,
    },
    homeinput: {
        flex: 1,
        height: '100%',
        padding: '0 20px',
        width: '100%',
    },
    textSearchBar: {
        width: '125px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    iconSearchBar: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'unset',
            padding: '5px 0',
        },
    },
    buttonCity: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'calc(100% - 145px)',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    city: {
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
        width: '150px',
        margin: '2px',
    },
})
