import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { fetchData } from '../../../actions/api.actions'
import { API_URL } from '../../../services/config.service'
import styles from '../../common/UsefullComponents/Styles/SearchBarCss'
import CircularProgress from '@material-ui/core/CircularProgress'

class SearchCityBar extends Component {
    constructor(props) {
        super(props);
        console.log("test 1")
        console.log(props)

        this.state = {
            search: null,
        }

        this.autoCompletion = this.autoCompletion.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    autoCompletion() {
        if (this.state.search.length > 1) {
            const data = new FormData()
            const searchBar = this
            data.append('address', this.state.search)
            this.props.dispatch(
                fetchData(
                    API_URL + '/igncityautocompletion.php',
                    'autoCompletion',
                    'POST',
                    data,
                    null,
                    json => {
                        console.log(json)
                        console.log("json")
                        searchBar.props.onResults(json)
                    },
                ),
            )
        }
    }

    asyncAutoCompletion(search) {
        var searchBar = this
        //Limitation des requetes
        setTimeout(() => {
            let fetch = search == searchBar.state.search
            if (fetch) searchBar.autoCompletion()
        }, 100)
    }

    handleChange(value) {
        this.props.onValueChange({
            lastSearch: this.props.searchType,
            lastValue: value,
        })
        this.setState({ search: value }, () => {
            this.asyncAutoCompletion(value)
        })
    }

    handleSearch(address) {
        console.log(address)
        window.location.assign('http://boaton.fr/fr/chercher-un-parking?'+ address.city +'?'+ address.x +'?'+address.y);
    }

    renderAutoCompletion() {
        const { classes } = this.props

        if (
            this.props.autoCompletion &&
            this.props.autoCompletion.results &&
            this.state.search
        ) {
            return (
                <ul
                    className={classes.autoCompletion}
                    style={{ listStyle: 'none' }}
                >
                    {this.props.autoCompletion.results
                        .filter(address => !address.zipcode.startsWith('000'))
                        .map((address, index) => (
                            <li
                                className={classes.field}
                                key={index}
                                onClick={() => {
                                    this.handleSearch(address)
                                }}
                            >
                                {address.city}
                            </li>
                        ))}
                </ul>
            )
        }

        return <div />
    }

    render() {
        const {
            classes,
            className,
            notOutlined,
            addressValue,
            textFieldClass,
            placeholder,
            onKeyDown,
        } = this.props

        return (
            <div className={className ? className : classes.root}>
                <div className={classes.search}>
                    <TextField
                        InputLabelProps={{ shrink: addressValue != '' }}
                        id="searchBar"
                        margin="dense"
                        variant={notOutlined ? 'standard' : 'outlined'}
                        label={this.props.label}
                        value={addressValue || ''}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        onChange={e => {
                            this.handleChange(e.target.value)
                        }}
                        className={
                            textFieldClass ? textFieldClass : classes.bar
                        }
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {!this.props.autoCompletion &&
                                    addressValue ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                </React.Fragment>
                            ),
                        }}
                    />
                </div>
                {this.renderAutoCompletion()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        autoCompletion: state.fetch.autoCompletion,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(SearchCityBar))
