import { userConstants } from '../constants'

const initialState = {
    user: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return Object.assign(prevState, {
                user: null,
            })
        case userConstants.SUBSCRIBE_SUCCESS:
        case userConstants.LOGIN_SUCCESS:
            return {
                ...prevState,
                user: action.user,
            }
        case userConstants.LOGIN_FAILURE:
            return {
                ...prevState,
                user: null,
            }
        case userConstants.LOGOUT:
            return {
                ...prevState,
                user: null,
            }
        default:
            return prevState
    }
}
