import React from 'react'
import Typography from '@material-ui/core/Typography'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import Button from '@material-ui/core/Button'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from '@mui/material'
import { positions } from '@mui/system'


const HoverPopoverPopupState = ({top, left, style , text, data}) => (

  <PopupState variant="popover" popupId="demoPopover">

    {(popupState) => (
      <div className={style} >
        <a   className={style} style={{display: 'flex', placeItems: 'center', position: 'relative', top: 10, paddingBottom:20}} variant="contained" {...bindHover(popupState)}>
          {text}
        <ExpandMoreIcon  style={{color: "gray", fontSize: 20, fontWeight: 400}} />    
        </a>
        <HoverPopover
          {...bindPopover(popupState)}
          // anchorReference="anchorPosition"
          // anchorPosition={{ top, left}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography  style={{ margin: 10, display:'flex', width:250, lineHeight:1.5, flexDirection: 'column',  justifyContent: 'flex-start' , borderTop: '2px solid #d0011c' }}>
           {data.map(element => 
                <a  className={style} style={{ fontSize: 18, padding: 15}} href={element.link}>{element.name}</a>
           )}
          </Typography>
        </HoverPopover>
      </div>
    )}
  </PopupState>
)

export default HoverPopoverPopupState
