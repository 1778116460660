import { blue } from "@material-ui/core/colors";

export default theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        position: 'relative',
    },
    appBar: {
        top: 0,
        position: 'fixed',
        width: '100%',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        marginTop: 122,
        padding: '20px',
        flexGrow: 1,
        backgroundColor: '#fff',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
            marginTop: 60,
        },
    },
    flex: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecorationLine: 'none',
        paddingRight: '10px',
        paddingLeft: '10px',
        color: '#000',
        '&:hover': {
            color: '#fff',
        },
        fontSize: '14px',
        lineHeight: '1.25',
    },
    category: {
        border: '1px solid #c4c4c4',
        width: '160px',
        height: '30px',
        margin: '3px',
        marginTop: '10px',
        marginBottom: '10px',
        boxShadow: '0px 2px 10px 0.5px #c4c4c4',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    textCategory: {
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        textAlign: 'left',
        color: '#000',
        fontSize: '14px',
        fontWeight: '500',
    },
    logotel: {
        marginTop: '2px',
        marginBottom: '2px',
        fill: '#000',
    },
    textPhone: {
        width: '155px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '10px',
        border: '2px solid #303F9F',
        borderRadius: '5px',
        textAlign: 'left',
        color: '#303f9f',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    flexImage: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 10,
        marginRight: 10,
        textDecorationLine: 'none',
        color: '#000',
    },
    store: {
        width: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '20px',
    },
    menu: {
        width: '100%',
        height: '64px',
        padding: '0 40px',
    },
    containedSizeSmall: {
        padding: '4px 20px',
    },
    topbarcontainer: {
        height: '30px',
        backgroundColor: '#D0021B',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    topbar: {
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infosBox: {
        display: 'flex',
        alignItems: 'center',
    },
    span: {
        margin: '0 8px',
        fontWeight: 'bold',
        fontFamily: 'Roboto'

    },
    topBarPhone: {
        fontSize: '12px',
        margin: '0',
        color: '#000',
        textDecoration: 'none',
    },
    topBarText: {
        fontSize: '12px',
        margin: '0',
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'row',
    },
    secondPart: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hideMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    buttonMenu: {
        textTransform: 'capitalize',
        fontSize: '22px',
        fontWeight: 'normal',
        paddingLeft: 20,
        paddingRight: 20,
    },
    buttonProfil: {
        fontSize: '1rem',
        fontWeight: '400',
        color: 'white',
        padding: '0',
    },
    li: {
        padding: 0,
        height: '100%',
        whiteSpace: 'normal',
    },
    active: {
        paddingRight: '10px',
        paddingLeft: '10px',
        height: '100%',
        color: '#fff',
        backgroundColor: '#D0021B',
        lineHeight: '1.25',
    },
    applications: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '245px',
        height: '36px',
        margin: 'auto',
        marginLeft: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    profileList: {
        minWidth: '300px',
    },
    profileMenuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        margin: '8px 0',
    },
    image: {
        width: '120px',
        height: '36px',
    },
    imgCategory: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    menuMobile: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuMobileItem: {
        marginRight: theme.spacing(2),
    },
    drawerBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    drawerPhone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    notificationBox: {
        padding: '10px',
    },
    profileItem: {
        color: '#000',
        textDecoration: 'none!important',
    },
    loginSection: {
        display: 'flex',
    },
    signinButton: {
        marginRight: '5px',
        '&:hover': {
            backgroundColor: '#d5d5d5',
        },
    },
    signupButton: {
        marginLeft: '5px',
    },
    signinTextButton: {
        color: '#000',
        textDecoration: 'none!important',
    },
    signupTextButton: {
        color: '#fff',
        textDecoration: 'none!important',
    },
    activeTextCategory: {
        color: '#D0021B',
        height: '30px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '500',
        position: 'relative',
        '&::before': {
            content: `''`,
            position: 'absolute',
            bottom: '-5px',
            width: '40px',
            height: '2px',
            backgroundColor: '#3040A0',
            marginLeft: 'calc(50% - 20px)',
        },
    },
    
    navText: {
        marginLeft: '25%',
        width: '300px',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    navTel: {
        marginLeft: '18%',
        width: '300px',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    exposant: {
        fontSize: '70%',
        verticalAlign: 'super'
    },

    navLinks: {
        textDecoration: "none",
        color: "black",
        cursor: "pointer",
        padding: 4,
        "&:hover": {
              color: '#4169e1',
            },
        [theme.breakpoints.down('sm')]: {
            display: "none",
          } 
    },

    entete: {
        display:"flex",
         placeItems: "center",
         height: 31,
        [theme.breakpoints.down('sm')]: {
            display: "none",
          }
        
    },
    hamburger:{
        position: 'absolute',
        right: 20,
        top: 12,
        backgroundColor: "#CF2518",
        color: "white",
        height: 30,
        width: 30,
        padding: 7,
        cursor:"pointer",
        borderRadius: "15%",
        [theme.breakpoints.up('md')]: {
            display: "none",
          }
    },

    ligne:{
        border: 1
    },
    navbtndiv:{
        [theme.breakpoints.down('sm')]: {
            display: "none",
          } 
    },
   
    chevron:{
        display: "inline",
        [theme.breakpoints.down('sm')]: {
            display: "none"
    }}
})
